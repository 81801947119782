import './App.scss';
import React from 'react';
import Character from './Character';
import { useEffect, useState } from 'react'
import { nanoid } from 'nanoid'
import GlobalMenu from './GlobalMenu'
import ShareDialog from './ShareDialog';
import D20 from './dice/D20';
import DiceRoller from './DiceRoller';
import History from './History';
import { useParams } from 'react-router-dom';

const testCharacters = [
  {
    id: nanoid(),
    name: "Example Name",
    initiative: 13,
    mod: 1,
    ac: 18,
    hp: 200,
    notes: "",
    reminders: [],
    counters: [],
    singleNotes: ["Grappled"],
  }
]

const compareCharacters = (a, b) => {
  console.log("Comparing ", a, "with ", b);
  if (a.initiative == null || b.initiative == null) {
    return a.initiative == null ? -1 : 1;
  }
  if (a.initiative === b.initiative) {
    // tie-braker
    return b.mod - a.mod;
  } else {
    return b.initiative - a.initiative;
  }
}

function App() {
  const [characters, setCharacters] = useState(testCharacters);
  const [round, setRound] = useState(1);
  const [activeId, setActiveId] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [shareKey, setShareKey] = useState(null);
  const [history, setHistory] = useState([]);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareError, setShareError] = useState(false);
  const [diceRollerOpen, setDiceRollerOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [latestCharacter, setLatestCharacter] = useState(null);
  const { saveid } = useParams();

  const onShortcutDown = (e) =>{
    if (e.ctrlKey && e.which === 32) {
      next();
    }
  }

  const save = () => {
    console.log("Saving...");
    // Save
    localStorage.setItem('save-' + saveid, JSON.stringify({
      characters: characters,
      round: round,
      activeId: activeId,
      shareKey: shareKey,
      history: history,
      lastSaved: Date.now()
    }))
    // Set this save as the most recent
    localStorage.setItem('latestSave', saveid);
    if (shareKey) {
      storeData();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onShortcutDown);

    return () => {
      document.removeEventListener('keydown', onShortcutDown);
    }
  })

  const isKeyValid = async (shareKey) => {
    console.log("Checking validity for key:", shareKey)
    try {
      const validResponse = await fetch(`${process.env.REACT_APP_SERVER}/iskeyvalid/${shareKey}`);
      const validResponseJson = await validResponse.json();
      if (validResponseJson.valid) {
        setShareKey(shareKey);
      } else {
        setShareKey(null);
      }
    } catch (e) {
      setShareKey(null);
    }

  }

  useEffect(() => {
    if (saveid === undefined || saveid === null) {
      return;
    }
    // Load
    const saved = localStorage.getItem('save-' + saveid);
    if (saved) {
      try {
        const parsed = JSON.parse(saved);
        setCharacters(parsed.characters);
        setRound(parsed.round);
        setActiveId(parsed.activeId);
        isKeyValid(parsed.shareKey);
        setHistory(parsed.history ? parsed.history : []);
      } catch (e) {
        console.log("Loading failed");
        console.log(e);
      }
    }
    setLoaded(true); // Initial loading complete
  }, [saveid])

  useEffect(() => {
    if (loaded) {
      save();
    }
  }, [characters, round, activeId, shareKey, history])

  useEffect(() => {
    // Active Changed, scroll to it
    const activeElement = document.getElementById(`Character-${activeId}`)
    if (activeElement) {
      activeElement.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest"});
    }
  }, [activeId])

  const handleNameChange = (e) => {
  }

  const sortCharacters = () => {
    const sorted = characters.sort(compareCharacters);
    setShouldRefresh(false);
    setCharacters([...sorted]);
  }


  const commence = () => {
    console.log("Setting active id");
    sortCharacters();
    if (activeId === null) {
      setActiveId(characters[0].id)
    }
  }

  const next = () => {
    let i;
    for (i = 0; i < characters.length; i++) {
      if (characters[i].id === activeId) {
        setActiveId(characters[(i + 1) % characters.length].id);
        if (i + 1 === characters.length) {
          const currentRound = round;
          setRound(currentRound + 1);
          historyAppend(`**Round ${currentRound + 1}** started.`);
        }
      }
    }
  }

  const exitInitiative = () => {
    setActiveId(null);
  }

  const addCharacter = () => {
    const newId = nanoid();
    const newCharacters = new Array(...characters, {
      id: newId,
      name: "New Character...",
      initiative: 0,
      mod: 0,
      ac: 0,
      hp: 0,
      maxHp: 0,
      notes: "Notes...",
      reminders: [],
      statblock: ''
    });
    setCharacters(newCharacters);
    setLatestCharacter(newId);
  }

  useEffect(() => {
    // TODO: The following should eventually become refs
    if (!latestCharacter) {
      return ;
    }
    const latestCharacterElem = document.getElementById(`Character-${latestCharacter}`);
    const activeElement = latestCharacterElem;
    const latestCharacterName = activeElement.querySelector(".NameArea input");
    if (latestCharacterName) {
      latestCharacterName.select(); // This could be a onFocus handler on the input itself
      latestCharacterName.focus();
    }
    if (activeElement) {
      activeElement.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest"});
      setLatestCharacter(null);
    }
  }, [latestCharacter])

  const handleCharChange = (data) => {
    const newCharacters = [...characters];
    for (let i = 0; i < characters.length; i++) {
      if (characters[i].id === data.id) {
        if (data.initiative !== characters[i].initiative) {
          setShouldRefresh(true);
        }
        newCharacters[i] = data;
      }
    }
    console.log("New chars:", newCharacters);
    setCharacters(newCharacters);
  }

  const reset = () => {
    localStorage.removeItem('save-' + saveid);
    const newCharacters = new Array();
    console.log(newCharacters);
    setCharacters(newCharacters);
    setRound(1);
    setActiveId(null);
    setShouldRefresh(false);
  }

  const loadTest = () => {
    setCharacters([...testCharacters]);
  }

  const duplicateCharacter = (id) => {
    const pos = characters.findIndex(char => char.id === id);
    if (pos !== -1) {
      const copied = {...characters[pos]};
      copied.id = nanoid();
      copied.name = copied.name + ' (Copy)';
      const charactersTemp = [...characters];
      charactersTemp.splice(pos + 1, 0, copied)
      setCharacters(charactersTemp);
    }
  }

  const deleteCharacter = (id) => {
    const pos = characters.findIndex(char => char.id === id);
    if (pos !== -1) {
      const charactersTemp = [...characters];
      charactersTemp.splice(pos, 1);
      setCharacters(charactersTemp);
    }
  }

  const storeData = async () => {
    if (process.env.REACT_APP_SHARE !== 'on') {
      return ;
    }
    try {
      setShareError(false);
      const response = await fetch(`${process.env.REACT_APP_SERVER}/storedata`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({data: {
            activeId,
            round,
            characters: characters.map(character => ({name: character.name, id: character.id}))
          }, key: shareKey || undefined})
      })
      const responseData = await response.json();
      setShareKey(responseData.key);
    } catch (e) {
      setShareError(true);
      console.log(e);
    }
  }

  const share = () => {
    storeData();
    setShareDialogOpen(true);
  }

  const historyAppend = (historyData) => {
    setHistory([...history, {time: Date.now(), ...historyData}]);
  }

  const clearHistory = () => {
    setHistory([]);
    setHistoryOpen(false);
  }

  return (
    <div>
      <div className="TopBar">
        <div id="LeftActions">
          <button onClick={addCharacter} id="NewCharacter" type='button'><span className='HideMobile'>Add Character </span><span className='fa-solid fa-plus'></span></button>{' '}
          {activeId === null && <button id='StartButton' onClick={commence} type='button'><span className='HideMobile'>Start </span><span className='fa-solid fa-caret-right'></span></button>}{' '}
          {activeId !== null && <button onClick={exitInitiative} type='button'><span className='HideMobile'>Exit Initiative </span><span className='fa-solid fa-square'></span></button>}{' '}
          {activeId !== null && 
            <button onClick={next} id='NextButton'>Next<span className='HideMobile'> Turn</span> <span className='fa-solid fa-arrow-right'></span></button>
          }{' '}
          {' '}
          {/* <button id="Refresh" type='button'>Update Order <span className='fa-solid fa-refresh'></span></button> */}
        </div>
        <div id="Rounds">
          {/* <button><span className='fa-solid fa-arrow-left'></span></button> */}
          <span className='RoundNumber'>{round}</span>
          <span className='TimeElapsed'><span className='fa-solid fa-clock' />{' '}
            {String(Math.floor((round - 1) * 6 / 60).toFixed(0)).padStart(2, '0')}
            :
            {String((round - 1) * 6 % 60).padStart(2, '0')}</span>
            {' '}
        </div>
        <div id="RightActions">
            {process.env.REACT_APP_SHARE && <span><button onClick={(e) => share()} type='button'><span className='HideMobile'>Share session</span><span className='OnltMobile fa-solid fa-share-nodes'></span></button></span>}
            {' '}
            <GlobalMenu handleStoreData={storeData} handleReset={reset} handleLoadTest={loadTest}/>
        </div>
      </div>
      {activeId !== null && <span id='GlobalIcon' class="fa-solid fa-shield-halved"/>}
      {(shouldRefresh || (activeId === null)) && (
        <div className='NoticeArea'>
          {shouldRefresh && activeId !== null &&
            <React.Fragment>
              <span>The order might have changed! </span>
              <button className='FancyButton' type='button' onClick={sortCharacters}>Refresh Order <span className='fa-solid fa-refresh'></span></button>)
              <br/>
            </React.Fragment>
          }
          {activeId === null &&
            <span>You are on <strong>planning mode</strong>. Click <i>Start <span className='fa-solid fa-caret-right'></span></i> to enter turn based mode.</span>
          }
        </div>
      )}
      {/* <div style={{position: 'absolute', top: '60px', width: '100px', left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto'}}>
        <img style={{width: '110px'}} src={`${process.env.PUBLIC_URL}/battletrack.png`}/>
      </div> */}
      <div className='MainArea'>
        {
          characters.map((character, index) => (
            <Character 
              commence={activeId !== null}
              round={round}
              onChange={handleCharChange}
              active={activeId === character.id}
              index={index} key={character.id}
              data={character}
              handleNameChange={handleNameChange}
              duplicateCharacter={duplicateCharacter}
              deleteCharacter={deleteCharacter}
              historyAppend={historyAppend}
              />
          ))
        }
      </div>
      {characters.length === 0 && (
        <div className='NoCharacters'>
          <p><em>[Rolls Perception]</em> No one seems to be here... <strong>yet</strong>!</p>
          <p><img src={`${process.env.PUBLIC_URL}/sad.jpg`} alt='Basil Underwood in Prison Cell'/></p>
          <p>You can add characters to the Scene using the <strong>Add Character</strong> button on the top bar.</p>
        </div>
      )}
      <div className='MainActions'>
      </div>
      <ShareDialog shareError={shareError} handleStoreData={storeData} shareKey={shareKey} open={shareDialogOpen} onClose={(e) => setShareDialogOpen(false)}/>
      <History open={historyOpen} onClose={(e) => setHistoryOpen(false)} history={history} clearHistory={clearHistory}/>
      <DiceRoller open={diceRollerOpen} onClose={(e) => setDiceRollerOpen(false)}/>
      <button onClick={(e) => {setHistoryOpen(true)}} id='HistoryButton' type='button'><span className='fa-solid fa-clock-rotate-left'/></button>
      <button onClick={(e) => {setDiceRollerOpen(true)}} id='DiceButton' type='button'><span className='fa-solid fa-dice-d20'/></button>
    </div>

  );
}

export default App;
