import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import './Start.scss';

export default function LoadGame() {
    const [saveIds, setSaveIds] = useState([]);

    useEffect(() => {
        const keys = [];
        for (const key in localStorage) {
            if (key.startsWith("save")) {
                keys.push(key.substring(5, key.length+1));
            }
        }
        setSaveIds(keys);
    }, [])

    const saves = useMemo(() => {
        const saves = [];
        for (const key of saveIds) {
            const parsed = JSON.parse(localStorage.getItem('save-' + key));
            if (parsed) {
                saves.push({
                    key,
                    characters: parsed.characters.map(character => character.name),
                    round: parsed.round,
                    lastSaved: parsed.lastSaved
                });    
            }
        }
        console.log(saves);
        return saves;
    }, [saveIds]);

    const deleteSave = (saveid, characters) => {
        if (window.confirm('Are you sure you want to delete save with: ' + characters.join(','))) {
            for (let i = 0; i < saveIds.length; i++) {
                if (saveIds[i] === saveid) {
                    localStorage.removeItem('save-' + saveid);
                    setSaveIds(saveIds.filter(key => key !== saveid));
                    break;
                }
            }
        }
    }

    return (
        <React.Fragment>
            <div className="TopBar">
                <div id="LeftActions">
                    <Link to="/"><span className='fa-solid fa-arrow-left'/></Link>
                </div>
                <div id="RightActions">
                </div>
            </div>
            {saves.length === 0 && (
                    <div className='NoCharacters'>
                        <p><em>[Rolls History]</em> There are no Scenes to be loaded <strong>yet</strong>!</p>
                        <p><img style={{opacity: '0.9'}} src={`${process.env.PUBLIC_URL}/magpie.jpg`} alt='Magpie Wood Engraving by Bewick, Thomas'/></p>
                    </div>
                )}
            <div className='MainArea'>
                {saves.map(save =>(
                    <div className='CharacterPill'>
                        <div className="OrderArea">
                            Last Saved: {save.lastSaved ? new Date(save.lastSaved).toLocaleString() : '-'}
                        </div>
                        <div className="SubArea">
                            <div className="DataArea">
                                <p>
                                    <strong>Characters: </strong>
                                    {save.characters.join(", ")}
                                </p>
                                <p>
                                    <strong>Round: </strong>
                                    {save.round}
                                </p>
                                <Link to={`/initiative/${save.key}`} className="FancyButton">Load</Link>
                                {' '}
                                <button className='FancyButton' style={{backgroundColor: '#ddaaaa'}} onClick={() => deleteSave(save.key, save.characters)}>Delete <span className="fa-solid fa-trash"/></button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}